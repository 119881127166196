import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { css } from 'linaria'
import { useHubspotForm } from '@aaronhayes/react-use-hubspot-form'
import { useLocation } from '@reach/router'
import IconCheck from '~/images/icon/check.svg'
import { STYLE } from '~/utils/constant'
import { rem } from '~/utils/helper'

const downloadDocumentStyle = css`
  & > .wrapper {
    padding: 64px 24px;
    background: var(--color-background-bluegray50);

    @media screen and (${STYLE.MEDIA.PC}) {
      padding-top: 128px;
      padding-bottom: 128px;
    }
  }

  & > .wrapper > .body > .title {
    ${STYLE.MIXIN.CONTENT_TITLE}
  }

  & > .wrapper > .body > .text {
    margin-top: 32px;
    padding-left: var(--font-letter-spacing);
    font-size: ${rem(16)};
    font-weight: 400;
    text-align: center;
  }

  & > .wrapper > .body > .content {
    margin-top: 32px;

    @media screen and (${STYLE.MEDIA.TABLET}) {
      display: flex;
      max-width: var(--width-max-content);
      margin-top: 64px;
      margin-right: auto;
      margin-left: auto;
    }
  }

  & > .wrapper > .body > .content > .form {
    margin-top: 72px;

    @media screen and (${STYLE.MEDIA.TABLET}) {
      flex-basis: 470px;
      min-width: 50%;
      margin-top: 0;
    }

    @media screen and (${STYLE.MEDIA.PC}) {
      min-width: 470px;
    }
  }

  & > .wrapper > .body > .content > .description {
    margin-top: 32px;

    @media screen and (${STYLE.MEDIA.TABLET}) {
      flex-grow: 1;
      margin-top: 0;
      margin-right: 48px;
    }
  }

  & > .wrapper > .body > .content > .description > .imagewrapper {
    display: block;
    padding: 32px;
    border-radius: 8px 8px 0 0;
    background: var(--color-background-bright);
  }

  & > .wrapper > .body > .content > .description > .imagewrapper > .image {
    display: block;
    width: 100%;
    height: auto;
  }

  & > .wrapper > .body > .content > .description > .points {
    padding: 32px;
    border-radius: 0 0 8px 8px;
    background: var(--color-gradient-key);
  }

  & > .wrapper > .body > .content > .description > .points > .title {
    position: relative;
    width: 12em;
    margin-right: auto;
    margin-left: auto;
    color: var(--color-text-bright);
    font-size: ${rem(20)};
    font-weight: bold;
    text-align: center;

    &::before,
    &::after {
      content: '';
      position: absolute;
      bottom: 2px;
      width: 3px;
      height: 28px;
      border-radius: 10px;
      background-color: var(--color-background-bright);
    }

    &::before {
      left: 0;
      transform: rotate(-12deg);
    }

    &::after {
      right: 0;
      transform: rotate(12deg);
    }
  }

  & > .wrapper > .body > .content > .description > .points > .title > .text {
    display: block;

    &::before,
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      width: 3px;
      height: 16px;
      border-radius: 10px;
      background-color: var(--color-background-bright);
    }

    &::before {
      left: -8px;
      transform: rotate(-36deg);
    }

    &::after {
      right: -8px;
      transform: rotate(36deg);
    }
  }

  & > .wrapper > .body > .content > .description > .points > .point {
    position: relative;
    width: 14em;
    margin-top: 16px;
    margin-right: auto;
    margin-left: auto;
    padding-left: 2em;
    color: var(--color-text-bright);
    font-size: ${rem(16)};
    font-weight: 700;

    @media screen and (${STYLE.MEDIA.PC}) {
      width: 22em;
    }
  }

  & > .wrapper > .body > .content > .description > .points > .point > .icon {
    position: absolute;
    top: -0.1em;
    left: 0;
    width: 1em;
  }

  & > .wrapper > .body > .content > .description > .points > .point > .iconwrapper {
    display: flex;
    position: absolute;
    top: 0.15em;
    left: 0;
    align-items: center;
    justify-content: center;
    width: 1.3em;
    height: 1.3em;
    overflow: hidden;
    border-radius: 50%;
    background: var(--color-background-bright);
  }

  & > .wrapper > .body > .content > .description > .points > .point > .iconwrapper > .icon {
    width: ${rem(16)};
    color: var(--color-text-key);
    line-height: 1;

    & path {
      fill: var(--color-text-key);
    }
  }

  & > .wrapper > .body > .content > .description > .points > .title + .point {
    margin-top: 32px;
  }

  & > .wrapper > .body > .privacymark {
    display: flex;
    align-items: center;
    max-width: 640px;
    margin-top: 64px;
    margin-right: auto;
    margin-left: auto;
    column-gap: 16px;
  }

  & > .wrapper > .body > .privacymark > .imagewrapper {
    min-width: 64px;

    @media screen and (${STYLE.MEDIA.TABLET}) {
      min-width: 100px;
    }
  }

  & > .wrapper > .body > .privacymark > .text {
    color: var(--color-text-sub);
    font-size: ${rem(11)};

    @media screen and (${STYLE.MEDIA.TABLET}) {
      font-size: ${rem(12)};
    }
  }
`

const DownloadDocumentComponent = () => {
  const { pathname } = useLocation()
  if (process.env.HUBSPOT_PORTAL_ID) {
    useHubspotForm({
      portalId: process.env.HUBSPOT_PORTAL_ID,
      formId: '68832486-8ac5-4d51-9166-ad76c6ccbc39',
      target: '#download-form',
    })
  }
  return (
    <aside className={downloadDocumentStyle}>
      <div className="wrapper">
        <div className="body">
          <p className="title content-title">
            「1分でわかるASHIATO」
            <br className="_mobile-inline" />
            ダウンロード
          </p>
          <p className="text">
            ASHIATOの概要が分かる資料を
            <br className="_mobile-inline" />
            ダウンロードできます
          </p>

          <div className="content">
            <div className="description">
              <div className="imagewrapper">
                <StaticImage
                  className="image"
                  src="../../images/no_1.png"
                  placeholder="none"
                  alt="1分でわかるASHIATO"
                />
              </div>
              <dl className="points">
                <dt className="title">
                  <span className="text">こんなことが分かります！</span>
                </dt>
                <dd className="point">
                  <div className="iconwrapper">
                    <IconCheck className="icon" />
                  </div>
                  リファレンスチェックとは？
                </dd>
                <dd className="point">
                  <div className="iconwrapper">
                    <IconCheck className="icon" />
                  </div>
                  バックグラウンドチェックとは？
                </dd>
                <dd className="point">
                  <div className="iconwrapper">
                    <IconCheck className="icon" />
                  </div>
                  ASHIATO導入メリット・
                  <br className="_mobile-tablet-inline" />
                  活用シーン
                </dd>
                <dd className="point">
                  <div className="iconwrapper">
                    <IconCheck className="icon" />
                  </div>
                  他社リファレンスチェックと
                  <br className="_mobile-tablet-inline" />
                  ASHIATOの違い
                </dd>
              </dl>
            </div>

            <div id="download-form" className="form" />
          </div>
          {pathname === '/backgroundcheck' && (
            <div className="privacymark">
              <a href="http://privacymark.jp/" target="_blank" rel="nofollow noreferrer" className="imagewrapper">
                <StaticImage
                  className="image"
                  src="../../images/privacymark.png"
                  placeholder="none"
                  alt="プライバシーマーク"
                />
              </a>
              <p className="text">
                当社は、個人情報について適正な保護措置を講ずる事業者として、プライバシーマークを付与されております。個人情報の取得・利用及び提供については、「個人情報のお取り扱いについて」に記載する利用目的の範囲内で、情報サービス等の内容や規模を考慮して適切に取り扱います。当社は、取得または預託された個人情報を利用目的以外に利用致しません。
              </p>
            </div>
          )}
        </div>
      </div>
    </aside>
  )
}

export default DownloadDocumentComponent
