import React from 'react'
import { graphql, Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { css } from 'linaria'
import Layout from '~/components/Common/Layout'
import DownloadDocument from '~/components/Common/DownloadDocument'
import IconCheck from '~/images/icon/check.svg'
import imageLogo from '~/images/case/logo_ashiato.png'
import { Query } from '~/types/graphql-types'
import { STYLE } from '~/utils/constant'
import { getGatsbyImageData, rem, replaceImage } from '~/utils/helper'

const caseDetailStyle = css`
  display: flex;
  flex-direction: column;
  max-width: var(--width-max-content);
  margin-right: auto;
  margin-bottom: 64px;
  margin-left: auto;
  padding-top: calc(var(--height-header) + 56px);

  @media screen and (${STYLE.MEDIA.TABLET}) {
    display: grid;
    grid-template-areas:
      'title company'
      'body company';
    grid-template-columns: 1fr 188px;
    grid-template-rows: auto 1fr;
    margin-bottom: 128px;
    padding-top: 80px;
    padding-right: 32px;
    padding-left: 32px;
    gap: 0 16px;
  }

  @media screen and (${STYLE.MEDIA.PC}) {
    grid-template-columns: 1fr 236px;
    grid-template-rows: auto 1fr;
  }

  & > .title {
    order: 1;
    padding: 32px 24px;
    border-radius: 16px;
    background: var(--color-background-bright);

    @media screen and (${STYLE.MEDIA.TABLET}) {
      grid-area: title;
      padding: 48px 48px 0;
      border-radius: 16px 16px 0 0;
    }
  }

  & > .title > .text {
    font-size: ${rem(32)};
    font-weight: 700;
  }

  & > .title > .imagewrapper {
    position: relative;
    margin-top: 24px;
  }

  & > .title > .imagewrapper > .image {
    display: block;
    width: 100%;
    height: auto;
  }

  & > .title > .imagewrapper > .logowrapper {
    display: none;
    position: absolute;
    right: 0;
    bottom: 0;
    background: var(--color-background-bright);

    @media screen and (${STYLE.MEDIA.TABLET}) {
      display: block;
    }
  }

  & > .title > .imagewrapper > .logowrapper img {
    display: block;
    width: auto;
    height: 24px;
    margin: 12px 14px;
  }
`

const caseDetailBodyStyle = css`
  order: 3;
  margin-top: 24px;
  padding: 32px 24px;
  border-radius: 16px;
  background: var(--color-background-bright);

  @media screen and (${STYLE.MEDIA.TABLET}) {
    grid-area: body;
    margin-top: 0;
    padding: 48px;
    border-radius: 0 0 16px 16px;
  }

  & > .button {
    text-align: center;
  }

  & > .button > a {
    display: inline-block;
    min-width: 288px;
    margin-top: 48px;
    padding: 19px 62px 18px;
    border: 1px solid var(--color-background-key);
    border-radius: 32px;
    font-size: ${rem(16)};
    font-weight: bold;
    letter-spacing: 3.2px;
    line-height: 1.6875;
    text-decoration: none;
  }

  & img {
    max-width: 100%;
    height: auto;
  }

  & > .reason {
    padding: 0 24px 24px;
    border: 1px solid var(--color-border-key);
    border-radius: 8px;
  }

  & > .reason > .title {
    margin-top: 24px;
    color: var(--color-text-key);
    font-size: ${rem(24)};
    font-weight: 700;
  }

  & > .reason > .body {
    font-size: ${rem(24)};
    font-weight: 700;
  }

  & > .reason > .body > .text {
    margin-top: 16px;
    color: var(--color-text-key);
    font-size: ${rem(16)};
    font-weight: 700;
    line-height: 1.68;
  }

  & > .points {
    margin-top: 32px;
    padding: 24px;
    border-radius: 8px;
    background: var(--color-background-key-bright);
  }

  & > .points > .title {
    font-size: ${rem(16)};
    font-weight: 700;
  }

  & > .points > .body {
    position: relative;
    padding-left: 32px;
  }

  & > .points > .body > .iconWrapper {
    display: flex;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    margin-top: auto;
    margin-bottom: auto;
    border-radius: 50%;
    background: var(--color-background-key);
    color: var(--color-background-key-bright);
    font-size: 18px;
    letter-spacing: normal;
  }

  & > .points > .body > .iconWrapper > .icon {
    width: 0.95em;
    height: 0.95em;
  }

  & > .points > .body > .text {
    margin-top: 16px;
    font-size: ${rem(16)};
    font-weight: normal;
  }

  & > .content > .title {
    margin-top: 48px;
    color: var(--color-text-key);
    font-size: ${rem(24)};
    font-weight: 700;
  }

  & > .content > .interviews > .interview {
    margin-top: 24px;
    font-size: ${rem(16)};
    line-height: 1.68;

    &.-listener {
      position: relative;
    }
  }

  & > .content > .interviews > .interview.-listener > .name {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 40px;
    height: 40px;
    margin-top: auto;
    margin-bottom: auto;
  }

  & > .content > .interviews > .interview.-answerer > .name {
    display: none;

    &.-is-show {
      display: inline;
      font-weight: 700;

      &::after {
        content: '：';
      }
    }
  }

  & > .content > .interviews > .interview.-listener > .name > .image {
    width: 40px;
    height: 40px;
  }

  & > .content > .interviews > .interview.-listener > .body {
    min-height: 40px;
    padding-left: 64px;
  }

  & > .content > .interviews > .interview.-listener > .body > .text {
    font-weight: 700;
  }

  & > .content > .interviews > .interview.-answerer > .body {
    display: inline;
  }

  & > .content > .interviews > .interview.-answerer > .body > .text {
    display: inline;
  }

  & > .content > .interviews > .interview.-answerer > .body > .text + .text {
    display: block;
    margin-top: 1em;
  }
`

const caseDetailCompanyStyle = css`
  order: 2;

  @media screen and (${STYLE.MEDIA.TABLET}) {
    grid-area: company;
  }

  & > .body {
    margin-top: 24px;
    margin-right: 24px;
    margin-left: 24px;
    padding: 24px;
    border-radius: 16px;
    background: var(--color-background-bright);

    @media screen and (${STYLE.MEDIA.TABLET}) {
      margin-top: 0;
      margin-right: 0;
      margin-left: 0;
    }
  }

  & > .body > .logowrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 132px;
    border: 1px solid var(--color-border-grey300);

    @media screen and (${STYLE.MEDIA.TABLET}) {
      height: 94px;
    }
  }

  & > .body > .logowrapper img {
    display: block;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: calc(100% - 48px);
    height: calc(100% - 48px);
    margin: auto;
  }

  & > .body > .title {
    margin-top: 16px;
    color: var(--color-text-grey500);
    font-size: ${rem(12)};
    font-weight: 700;
  }

  & > .body > .details > .title {
    margin-top: 24px;
    font-size: ${rem(12)};
    font-weight: 700;
  }

  & > .body > .details > .body {
    margin-top: 6px;
  }

  & > .body > .details > .body > .text {
    font-size: ${rem(12)};
    letter-spacing: normal;
  }

  & > .body > .details > .body > .profiles > .title {
    display: flex;
    justify-content: center;
    margin-top: 24px;
  }

  & > .body > .details > .body > .profiles > .title > .image {
    display: block;
    width: 48px;
    height: 48px;
    border-radius: 50%;
  }

  & > .body > .details > .body > .profiles > .title > .textwrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
    margin-left: 10px;
  }

  & > .body > .details > .body > .profiles > .title > .textwrapper > .nameen {
    font-size: ${rem(10)};
    letter-spacing: normal;
  }

  & > .body > .details > .body > .profiles > .title > .textwrapper > .name {
    margin-top: 2px;
    font-size: ${rem(12)};
    font-weight: 700;
  }

  & > .body > .details > .body > .profiles > .body {
    margin-top: 8px;
  }

  & > .body > .details > .body > .profiles > .body > .text {
    font-size: ${rem(12)};
    letter-spacing: normal;
  }
`

export const query = graphql`
  query ($id: String!) {
    allFile(filter: { name: { eq: "cms-case" } }) {
      edges {
        node {
          childrenImageSharp {
            gatsbyImageData(placeholder: NONE)
          }
          url
          name
          id
        }
      }
    }
    microcmsStgCase(id: { eq: $id }) {
      id
      title
      seo_title
      seo_description
      stgCaseId
      image_main {
        url
      }
      dounyu
      content {
        content_area
        content_midashi
        content_sub_midashi
      }
      logo {
        url
      }
      name
      number
      outline
      interview {
        img {
          height
          url
          width
        }
        introduction
        name
        name_en
      }
      point {
        article_point_detail
      }
    }
  }
`

const CaseDetailPage = ({ data }: { data: Query }) => {
  const pageData = data.microcmsStgCase
  if (!pageData) return

  const breadcrumb = [
    {
      label: '導入事例',
      path: '/case',
    },
    {
      label: pageData.name || '',
    },
  ]
  const seo = {
    title: `${pageData.seo_title || pageData.name || ''} | 導入事例`,
    description: pageData.seo_description || '',
    ogImage: pageData.image_main && pageData.image_main.url ? pageData.image_main.url : '',
  }
  const gatsbyImageData =
    pageData.image_main && pageData.image_main.url ? getGatsbyImageData(data.allFile, pageData.image_main.url) : null
  const gatsbyImageLogoData =
    pageData.logo && pageData.logo.url ? getGatsbyImageData(data.allFile, pageData.logo.url) : null
  return (
    <Layout breadcrumb={breadcrumb} seo={seo}>
      <main className={caseDetailStyle}>
        <div className="title">
          <h1 className="text">{pageData.title}</h1>
          <div className="imagewrapper">
            <GatsbyImage className="image" image={gatsbyImageData} alt={pageData.name || ''} />
            <GatsbyImage
              className="logowrapper"
              image={gatsbyImageLogoData}
              alt={pageData.name || ''}
              objectFit="contain"
            />
          </div>
        </div>

        <div className={caseDetailBodyStyle}>
          <section className="reason">
            <h2 className="title">ASHIATO導入背景</h2>
            <div className="body">
              <div
                className="text"
                dangerouslySetInnerHTML={{
                  __html: `${pageData.dounyu}`,
                }}
              />
            </div>
          </section>
          <dl className="points">
            <dt className="title">この記事のポイント</dt>
            {pageData &&
              pageData.point &&
              pageData.point.map((point) => {
                if (!point || !point.article_point_detail) return
                return (
                  <dd key={point.article_point_detail} className="body">
                    <span className="iconWrapper">
                      <IconCheck className="icon" />
                    </span>
                    <div
                      className="text"
                      dangerouslySetInnerHTML={{
                        __html: `${point.article_point_detail}`,
                      }}
                    />
                  </dd>
                )
              })}
          </dl>

          <section className="content">
            {pageData &&
              pageData.content &&
              pageData.content.map((content, index) => {
                if (!content) return
                const subMidashi = content.content_sub_midashi ? (
                  <dl className="interview -listener">
                    <dt className="name">
                      <img src={imageLogo} alt="ASHIATO" width="40" height="40" className="image" />
                    </dt>
                    <dd className="body">
                      <p className="text">{content.content_sub_midashi}</p>
                    </dd>
                  </dl>
                ) : null
                return (
                  <React.Fragment key={index}>
                    <h2 className="title">{content.content_midashi}</h2>
                    <div className="interviews">
                      {subMidashi}
                      <div className="interview -answerer">
                        <div className="body">
                          {content.content_area && (
                            <div className="text">{replaceImage(content.content_area, data.allFile)}</div>
                          )}
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                )
              })}
          </section>

          <div className="button">
            <Link to="/case/">一覧へ戻る</Link>
          </div>
        </div>

        <section className={caseDetailCompanyStyle}>
          <div className="body">
            <GatsbyImage
              className="logowrapper"
              image={gatsbyImageLogoData}
              alt={pageData.name || ''}
              objectFit="contain"
              objectPosition="50% 50%"
            />
            <h2 className="title">{pageData.name}</h2>
            <dl className="details">
              <dt className="title">会社概要</dt>
              <dd className="body">
                <div
                  className="text"
                  dangerouslySetInnerHTML={{
                    __html: `${pageData.outline}`,
                  }}
                />
              </dd>
              <dt className="title">従業員数</dt>
              <dd className="body">
                <p className="text number">{pageData.number}</p>
              </dd>
              {pageData.interview && (
                <>
                  <dt className="title">インタビュー</dt>
                  <dd className="body">
                    <dl className="profiles">
                      {pageData.interview.map((item) => {
                        if (!item || !item.name || !item.img || !item.img.url) return
                        const gatsbyImageInterviewData = getGatsbyImageData(data.allFile, item.img.url)
                        return (
                          <React.Fragment key={item.name}>
                            <dt className="title">
                              <GatsbyImage className="image" image={gatsbyImageInterviewData} alt={item.name} />
                              <span className="textwrapper">
                                <span className="nameen">{item.name_en}</span>
                                <span className="name">{item.name}</span>
                              </span>
                            </dt>
                            <dd className="body">
                              <div
                                className="text"
                                dangerouslySetInnerHTML={{
                                  __html: `${item.introduction}`,
                                }}
                              />
                            </dd>
                          </React.Fragment>
                        )
                      })}
                    </dl>
                  </dd>
                </>
              )}
            </dl>
          </div>
        </section>
      </main>
      <DownloadDocument />
    </Layout>
  )
}

export default CaseDetailPage
